import { Route, Routes, Navigate } from 'react-router-dom';

import { lazy, Suspense, useEffect, useState } from 'react';
import { useAppDispatch } from '../hooks/useTypedSelector';
import { getAllForms } from '../slices/form/form-assessment-slice';
import { FormAssessmentResponse } from '../models/forms/form-assessment-response.model';
import LoadingSpinner from '../components/loader/loader';

const Dashboard = lazy(() => import('../pages/dashboard/dashboard'));
const NeedAssesment = lazy(() => import('../pages/assessments/self/assessment'));
const LandingPage = lazy(() => import('../pages/welcome/landing-page'));
const Signup = lazy(() => import('../pages/register/signup'));
const FinancialAssessment = lazy(() => import('../pages/assessments/financial/financial-assessment'));
const ChangeRequest = lazy(() => import('../pages/assessments/change/change-request'));
const EmailConfirmation = lazy(() => import('../pages/email-confirmation/email-confirmation'));
const MSALSignInPage = lazy(() => import('../pages/msal-signin/msal-signin'));
const AccountConfirmation = lazy(() => import('../pages/register/confirmation/confirmation'));
const UserManagement = lazy(() => import('../pages/admin/user-management'));

const RoutesNav = () => {
  const dispatch = useAppDispatch();
  const [formassessmentResponse, setFormassessmentResponse] = useState<FormAssessmentResponse[] | undefined>(undefined);
  const ids = ['e5cbd1e5-d39c-4705-9b15-4785e9316bf7', 'a287d371-217a-4181-ba05-0a7e93bf2ec8'];

  useEffect(() => {
    dispatch(getAllForms())
      .unwrap()
      .then((forms) => {
        if(forms.length > 0) {
          ids.forEach(id => {
            setFormassessmentResponse(forms.filter(form => form.schemaId === id));
          })  
        }
      })
      .catch((err) => {
        console.error('Err', err);
      });
  }, [setFormassessmentResponse]);

  return (
    <Suspense fallback={<LoadingSpinner />}>
      <Routes>
        <Route path='/:tenantName/email-confirmation' element={<EmailConfirmation />} />
        <Route path='/:tenantName/signup' element={<Signup />} />
        <Route path='/:tenantName/dashboard' element={
          <Dashboard />
        } />
        <Route path='/:tenantName/assessment' element={<NeedAssesment 
          assessment={formassessmentResponse! &&
          formassessmentResponse.find(x => x.schemaId === ids[1])! }/>} />
        <Route path='/:tenantName/financialassessment' element={<FinancialAssessment 
          assessment={formassessmentResponse! && 
          formassessmentResponse.find(x => x.schemaId === ids[0])! }/>} />
        <Route path='/:tenantName/changerequest' element={<ChangeRequest 
          assessment={formassessmentResponse! &&
          formassessmentResponse.find(x => x.schemaId === ids[2])! }/>} />
        <Route path='/:tenantName/confirmation' element={<AccountConfirmation />} />
        <Route path='/:tenantName/welcome' element={<LandingPage />} />
        <Route path='/msal-signin' element={<MSALSignInPage />} />
        <Route path="/*" element={<Navigate to='/access/welcome' /> } />
        <Route path='/:tenantName/user-management' element={<UserManagement pageNumber={1} />} />
      </Routes>
    </Suspense>
  )
}

export default RoutesNav;