export const b2cPolicies = {
  names: {
    signUpSignin: 'B2C_1_signin',
    passwordReset: 'B2C_1_PasswordReset'
  },
  authorities: {
    signUpSignin: {
      authority: 'https://devaccesscommunitygateway.b2clogin.com/devaccesscommunitygateway.onmicrosoft.com/B2C_1_signin'
    },
    passwordReset: {
      authority: 
        'https://devaccesscommunitygateway.b2clogin.com/devaccesscommunitygateway.onmicrosoft.com/B2C_1_PasswordReset'
    }
  },
  authorityDomain: 'devaccesscommunitygateway.b2clogin.com'
}