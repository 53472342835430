import http from './http-common';
import { SessionCreateModel } from '../models/session/session-create';
import { SessionResponse } from '../models/session/session-response';

class SessionDataService {
  readonly storeKey = 'accp:session';

  create(body: SessionCreateModel) {
    return http.post('/session', body);
  }

  get session(): {session: SessionResponse, tenantId: string, tenant: string } | null {
    const sessionJSON = window.sessionStorage.getItem(this.storeKey);
    if(sessionJSON == null)
      return null;
    try
    {
      return JSON.parse(atob(sessionJSON));
    }
    catch
    {
      return null;
    }
  }
  set session(value: {session: SessionResponse, tenantId: string, tenant: string } | null) {
    if(value == null)
    {
      this.clear();
      return;
    }
    window.sessionStorage.setItem(this.storeKey, btoa(JSON.stringify(value)));
  }

  clear() {
    window.sessionStorage.removeItem(this.storeKey);
  }
}

export default new SessionDataService();
