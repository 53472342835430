import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import { FormAssessmentResponse } from '../../models/forms/form-assessment-response.model';
import { FormRequest } from '../../models/forms/form-request';
import { UpdateFormRequest } from '../../models/forms/update-form-request';
import formAssessmentService from '../../services/form-assessment.service';
import { FormSubmitRequest } from '../../models/forms/form-submit-request';
import {  FormSubmitResponse } from '../../models/forms/form-submit-response';
import {  Submit } from '../../models/forms/submit';
import { AxiosHeaders } from 'axios';

export const getAllForms = createAsyncThunk(
  'formAssessments/getAll',
  async (_ , thunkApi) => {
    try {
      const response = await formAssessmentService.getAll();
      return response.data;
    } catch (err: any) {
      const message = err.message;
      return thunkApi.rejectWithValue(message);
    }
  },
);

export const getFormById = createAsyncThunk(
  'formAssessments/getFormById',
  async (
    { formId }: { formId: string },
    thunkApi,
  ) => {
    try {
      const response = await formAssessmentService.getFormById(formId);
      return response.data;
    } catch (err: any) {
      const message = err.message;
      return thunkApi.rejectWithValue(message);
    }
  },
);

export const saveForm = createAsyncThunk(
  'formAssessments/create',
  async (
    {
      formRequest,
    }: { formRequest: FormRequest },
    thunkApi,
  ) => {
    try {
      const response = await formAssessmentService.create(formRequest);
      response.status;
    } catch (err: any) {
      const message = err.message;
      return thunkApi.rejectWithValue(message);
    }
  },
);

export const updateForm = createAsyncThunk(
  'formAssessments/update',
  async (
    {
      formId,
      updateformRequest,
    }: {
      formId: string;
      updateformRequest: UpdateFormRequest;
    },
    thunkApi,
  ) => {
    try {
      const response = await formAssessmentService.update(formId, updateformRequest);
      response.status;
    } catch (err: any) {
      const message = err.message;
      return thunkApi.rejectWithValue(message);
    }
  },
);

export const submitForm = createAsyncThunk(
  'formAssessments/submit',
  async (
    {
      submitFormRequest,
    }: {
      submitFormRequest: FormSubmitRequest;
    },
    thunkApi,
  ) => {
    try {
      const response = await formAssessmentService.submit(submitFormRequest);
      return response.data;
    } catch (err: any) {
      const message = err.message;
      return thunkApi.rejectWithValue(message);
    }
  },
);

export const getSubmit = createAsyncThunk(
  'formAssessments/getSubmit',
  async (
    {
      page, 
      pageSize
    }: {
      page: number;
      pageSize: number;
    },
    thunkApi,
  ) => {
    try {
      const response = await formAssessmentService.getSubmit(page, pageSize);
      const submits: FormSubmitResponse={submits:[], totalPages:''};
      if(response.data)
        submits.submits = response.data;
      const headers = response.headers as AxiosHeaders
      if (headers && headers.has('total-pages'))
        submits.totalPages = response.headers['total-pages'];
      return submits;
    } catch (err: any) {
      const message = err.message;
      return thunkApi.rejectWithValue(message);
    }
  },
);

export interface FormAssessmentState {
  loading: boolean;
  hasForm: boolean;
  error: string | null;
  entities: FormAssessmentResponse[] | FormAssessmentResponse;
  reference: string;
  submitForms: Array<Submit>;
}

const initialState = {
  loading: false,
  hasForm: false,
  error: null,
  entities: [],
  reference: '',
  submitForms: [],
} as FormAssessmentState;

const formAssessmentSlice = createSlice({
  name: 'formAssessments',
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(getAllForms.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(getFormById.pending, (state, action) => {
        state.loading = true;
        state.hasForm = false;
      })
      .addCase(getAllForms.fulfilled, (state, action: PayloadAction<FormAssessmentResponse[]>) => {
        state.loading = false;
        state.hasForm = true;
        state.entities = action.payload;
      })
      .addCase(getFormById.fulfilled, (state, action) => {
        state.loading = false;
        state.hasForm = true;
        state.entities = action.payload;
      })
      .addCase(getAllForms.rejected, (state, action: PayloadAction<any>) => {
        state.hasForm = false;
        state.error = action.payload;
      })
      .addCase(getFormById.rejected, (state, action: PayloadAction<any>) => {
        state.error = action.payload;
        state.hasForm = false;
      })
      .addCase(submitForm.pending, (state) => {
        state.loading = true;
      })
      .addCase(submitForm.fulfilled, (state, action: PayloadAction<any>) => {
        state.loading = false;
        state.reference = action.payload;
      })
      .addCase(submitForm.rejected, (state, action: PayloadAction<any>) => {
        state.reference = '';
        state.error = action.payload;
      })
      .addCase(getSubmit.pending, (state) => {
        state.loading = true;
      })
      .addCase(getSubmit.fulfilled, (state, action: PayloadAction<any>) => {
        state.loading = false;
        state.submitForms = action.payload;
      })
      .addCase(getSubmit.rejected, (state, action: PayloadAction<any>) => {
        state.submitForms = [];
        state.error = action.payload;
      })
      .addDefaultCase((state, action) => {
        state.hasForm = false;
        state.entities = [];
      });
  },
});

const { reducer } = formAssessmentSlice;
export default reducer;
