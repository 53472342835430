import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import fileUploadService from '../../services/fileUpload.service';
import { UploadFileAction } from '../../Enums/UploadFileAction';

export const upload = createAsyncThunk(
  'fileUpload/post',
  async (
    {
      formId,
      fileUploadObj,
      uploadFileAction
    }: { formId: any, fileUploadObj: any, uploadFileAction: UploadFileAction },
    thunkApi,
  ) => {
    try {
      const response = await fileUploadService.post(fileUploadObj, formId, uploadFileAction);
      return response.data;
    } catch (err: any) {
      const message = err.message;
      return thunkApi.rejectWithValue(message);
    }
  },
);

export interface FileUploadState {
  loading: boolean;
  error: string | null;
  urn: string | null
}

const initialState = {
  loading: false,
  error: null,
  urn: null
} as FileUploadState

const fileUploadSlice = createSlice({
  name: 'fileUpload',
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(upload.pending, (state) => {
        state.loading = true;
      })
      .addCase(upload.fulfilled, (state, action: PayloadAction<any>) => {
        state.loading = false;
        state.urn = action.payload;
      })
      .addCase(upload.rejected, (state, action: PayloadAction<any>) => {
        state.urn = null;
        state.error = action.payload;
      })
      .addDefaultCase((state) => {
        state.urn = null;
      });
  },
});

const { reducer } = fileUploadSlice;
export default reducer;