import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { TenantModel } from '../../models/tenants/tenant-model';
import { TenantResponseModel } from '../../models/tenants/tenant-response.model';
import sessionService from '../../services/session.service';
import TenantDataService from '../../services/tenant.service';
import { TenantFileConfigurationModel } from '../../models/tenants/tenant-file-configuration.model';

function getCurrentTenantName(): string {
  if(sessionService.session != null)
    return sessionService.session.tenant;
  const loc = window.location.pathname .split('/');
  return loc.length < 2 || !loc[1] ? 'access' : loc[1]; 
}

export const getTenantConfigurationById = createAsyncThunk(
  'tenants/getConfigurationByTenantId',
  async (_, thunkApi) => {
    const tenantId = getCurrentTenantName();

    try {
      const response = await TenantDataService.getTenantById(tenantId);
      return response.data;
    } catch (err: any) {
      const message = err.message;
      return thunkApi.rejectWithValue(message);
    }
  }
);

export const saveTenantConfiguration = createAsyncThunk(
  'tenants/save',
  async ({tenantPostObj}: {tenantPostObj: TenantModel}, thunkApi) => {
    try {
      const response = await TenantDataService.create(tenantPostObj);
      response.status;
    } catch (err: any) {
      const message = err.message;
      return thunkApi.rejectWithValue(message);
    }
  }
);

export const getTenantFileConfig = createAsyncThunk(
  'tenants/getFileConfiguration',
  async (_, thunkApi) => {
    try {
      const response = await TenantDataService.getFileConfig();
      return response.data;
    } catch (err: any) {
      const message = err.message;
      return thunkApi.rejectWithValue(message);
    }
  }
);

export interface TenantState {
	loading: boolean;
	error: string | null;
	entities: TenantResponseModel;
  fileConfig: TenantFileConfigurationModel | null
}

const initialState = {
  loading: false,
  error: null,
  entities: {},
  fileConfig: null
} as TenantState;

const tenantSlice = createSlice({
  name: 'tenants',
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(getTenantConfigurationById.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(getTenantConfigurationById.fulfilled, (state, action) => {
        state.loading = false;
        state.entities = action.payload;
      })
      .addCase(getTenantConfigurationById.rejected, (state, action: PayloadAction<any>) => {
        state.error = action.payload;
      })
      .addCase(getTenantFileConfig.pending, (state) => {
        state.loading = true;
      })
      .addCase(getTenantFileConfig.fulfilled, (state, action) => {
        state.loading = false;
        state.fileConfig = action.payload;
      })
      .addCase(getTenantFileConfig.rejected, (state, action: PayloadAction<any>) => {
        state.error = action.payload;
      })
      .addDefaultCase((state) => {
        state.entities = {id: '', name: '', logo: '', cssStyle: ''}
        state.fileConfig = {fileSize: 0, fileType: []}
      });
  }
});

const { reducer } = tenantSlice;
export default reducer;