import { UserAccountModel } from '../models/users/user-account.model';
import { UserModel } from '../models/users/user.model';
import http from './http-common';

class UserDataService {
  create(tenantId: string, body: UserModel) {
    return http.post('/users/registration', body, {
      headers: {
        tenantId: tenantId,
      },
    });
  }

  activateAccount(tenantId: string, activationCode: string) {
    return http.post(
      '/users/activate',
      { code: activationCode },
      {
        headers: {
          tenantId: tenantId,
        },
      },
    );
  }

  resendActivationCode(tenantId: string, emailAddress: string) {
    return http.post(
      '/users/resendActivationCode',
      { emailAddress: emailAddress },
      {
        headers: {
          tenantId: tenantId,
        },
      },
    );
  }

  getAll(pageSize: number, page: number) {
    return http.get<UserAccountModel[]>(
      `/users?pageSize=${pageSize}&page=${page}`
    );
  }
}

export default new UserDataService();
