import http from './http-common';
import { FormRequest } from '../models/forms/form-request';
import { UpdateFormRequest } from '../models/forms/update-form-request';
import { FormAssessmentResponse } from '../models/forms/form-assessment-response.model';
import { FormSubmitRequest } from '../models/forms/form-submit-request';
import { Submit } from '../models/forms/submit';

class FormAssessmentService {
  getAll() {
    return http.get<FormAssessmentResponse[]>('/Forms');
  }

  getFormById(formId: string) {
    return http.get<FormAssessmentResponse>(`/Forms/${formId}`);
  }

  create(body: FormRequest) {
    return http.post('/Forms', body);
  }

  update(formId: string, body: UpdateFormRequest) {
    return http.put(`/Forms/${formId}/`, body);
  }
  
  submit(body: FormSubmitRequest) {
    return http.post<string>('/Forms/submit/', body)
  }

  getSubmit(page: number, pageSize: number) {
    return http.get<Array<Submit>>(`/Forms/submit/?page=${page}&pageSize=${pageSize}`);
  }
}

export default new FormAssessmentService();