import { EventType, InteractionType } from '@azure/msal-browser';
import { MsalProvider } from '@azure/msal-react';
import { useEffect } from 'react';
import './App.scss';
import { forgotPasswordRequest, tokenRequest } from './authConfig';
import RoutesNav from './routes';

const App = ({ msalInstance }: any) => {

  useEffect(() => {
    const callbackId = msalInstance.addEventCallback((message: any) => {
      if (message.eventType === EventType.LOGIN_FAILURE && message.interactionType === InteractionType.Redirect) {
        if (message.error.errorMessage && message.error.errorMessage.indexOf('AADB2C90118') > -1) {
          msalInstance.loginRedirect(forgotPasswordRequest)
        } else {
          msalInstance.loginRedirect(tokenRequest)
        }
      } 
    });

    return () => {
      if (callbackId) {
        msalInstance.removeEventCallback(callbackId);
      }
    };
  }, [msalInstance]);

  return (
    <div className="govuk-body govuk-template__body">
      <MsalProvider instance={msalInstance}>
        <RoutesNav />
      </MsalProvider>
    </div>
  );
}

export default App;