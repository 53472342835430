import axios from 'axios';
import { PublicClientApplication } from '@azure/msal-browser';
import { msalConfig, tokenRequest } from '../authConfig';
import sessionService from './session.service';

export const msalInstance = new PublicClientApplication(msalConfig);

const instance = axios.create({
  baseURL: process.env.REACT_APP_GATEWAY_URI,
});

instance.interceptors.request.use(
  async (config) => {
    if (config.headers) {
      config.headers['Content-Type'] = 'multipart/form-data';
      config.headers['Access-Control-Allow-Origin'] = '*';
      config.headers['Access-Control-Allow-Methods'] = 'GET,PUT,POST,DELETE,PATCH,OPTIONS';
    }

    const account = msalInstance.getAllAccounts()[0];
    if (account) {
      const accessTokenResponse = await msalInstance.acquireTokenSilent({
        scopes: tokenRequest.scopes,
        account: account,
      });

      if (accessTokenResponse) {
        const accessToken = accessTokenResponse.accessToken;
        const session = sessionService.session?.session || null;

        if (config.headers && accessToken) {
          config.headers['Authorization'] = 'Bearer ' + accessToken;
        }
        if (config.headers && session) {
          config.headers['accp-sessionid'] = session.sessionId;
        }
      }
    }
    return config;
  },
  (error) => {
    Promise.reject(error);
  },
);

export default instance;
